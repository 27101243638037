import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Search from "../components/Search"
import InnerPadding from "../components/InnerPadding"

const SearchTitle = styled.h1`
    font-size: 3rem;
    display: inline-block;
    span {
        z-index: 101;
        position: relative;
    }
    &::after {
        content: "";
        height: 1.2rem;
        background-color: #f0f0ff;
        display: block;
        position: relative;
        z-index: 100;
        top: -1.2rem;
    }    
`

const SearchPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const searchIndex = data.siteSearchIndex.index

  return (
    <Layout title={siteTitle}>
      <Seo title="Search" />
      <InnerPadding>
        <SearchTitle>
          <span>Search</span>
        </SearchTitle>
        <Search searchIndex={searchIndex}/>
      </InnerPadding>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    siteSearchIndex {
        index
    }
  }
`